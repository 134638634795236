// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,

	appTitle: 'All American',

	// local
	// siteURL: 'http://localhost:8100/',
	// powerRooterAPI: 'http://localhost/all_american/web/',

	// beta
	siteURL: 'https://beta.allamerican.webarysites.com/',
	powerRooterAPI: 'https://api.beta.allamerican.webarysites.com/web/',

	// live
	// siteURL: 'https://allamerican.webarysites.com/',
	// powerRooterAPI: 'https://api.allamerican.webarysites.com/web/',

	crmURL: '',
	logoURL: 'assets/images/logo.png',

	defaultSuccessTitle: 'Whoop!',
	defaultSuccessMsg: 'That\'s done!',
	defaultErrorTitle: 'Oops!',
	defaultErrorMsg: 'Something went wrong!',
	defaultAuthErrorTitle: 'Access Denied!',
	defaultAuthErrorMsg: 'Please login to continue.',
	defaultAccessibilityErrorTitle: 'Access Denied!',
	defaultAccessibilityErrorMsg: 'You don\'t have an access to this feature.',
	defaultNoResultErrorMsg: 'No record found!',
};
