import { Component, ViewChild, OnInit, OnDestroy, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/internal/operators';

import { IdeateHelper } from '../core/services/ideate';
import { App } from '../providers/app';
import { Account } from '../providers/account';

import { ContractsEntryComponent } from './../contracts-entry/contracts-entry.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContractsComponent implements OnInit, OnDestroy {

  @ViewChild('infoModal') infoModal: ModalDirective;
  @ViewChild('entryModal') entryModal: ModalDirective;
  @ViewChild('cancelServiceModal') cancelServiceModal: ModalDirective;
  @ViewChild('cancelContractModal') cancelContractModal: ModalDirective;
  @ViewChild('archiveContractModal') archiveContractModal: ModalDirective;
  bsModalRefEntry: BsModalRef;

  public filterKeyword: string = '';
  private filterInputsChangedSub: Subject<string> = new Subject<string>();
  private services: any[] = [];
  private servicesAddOns: any[] = [];
  private billingManagers: any[] = [];
  public selectedContract: any = false;
  public contractsLists: any[] = [
    { title: 'Past due contracts', type: 'past_due', showArchiveContracts: false, showPreviouslyRenewedContracts : false},
    { title: 'Active contracts', type: 'active', showArchiveContracts: false, showPreviouslyRenewedContracts: false},
    { title: 'Ended contracts', type: 'ended', showArchiveContracts: false, showPreviouslyRenewedContracts: false},
    /* { title: 'Add-On Services', type: 'add_on', showArchiveContracts: false, showPreviouslyRenewedContracts: false} */
  ];

  constructor(
    private route: ActivatedRoute,
    public helper: IdeateHelper,
    private account: Account,
    private modalService: BsModalService,
    private app: App,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {

    this.filterInputsChangedSub
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(model => {
        this.initContractsLists();
      });

    this.getServices();
    this.getServicesAddOns();
    this.getBillingManagers();
    this.initContractsLists();

  }

  ngOnDestroy() {}

  getServices() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.is_add_on = 0;
    this.services = [];
    this.helper.makeAPIRequest('services/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.services = result.data.record;
      }
    });
  }

  getServicesAddOns() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.is_add_on = 1;
    this.servicesAddOns = [];
    this.helper.makeAPIRequest('services/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.servicesAddOns = result.data.record;
      }
    });
  }

  getBillingManagers() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.billing_managers_only = 1;
    this.helper.makeAPIRequest('customers/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.billingManagers = result.data.record;
      }
    });
  }

  initContractsLists(listIndex = -1) {
    for (let i = 0; i < this.contractsLists.length; i++) {
      if(listIndex==-1 || listIndex==i){
        this.contractsLists[i].isLoading = false;
        this.contractsLists[i].page = -1;
        this.contractsLists[i].isEOL = false;
        this.contractsLists[i].records = [];
        this.getContracts(i, (this.contractsLists[i].page + 1));
      }
    }
  }

  evFilterInputsChanged(query: any) {
    this.filterInputsChangedSub.next(query);
  }

  loadMoreContracts(idxContractList) {
    if (!this.contractsLists[idxContractList].isEOL) {
      this.getContracts(idxContractList, (this.contractsLists[idxContractList].page + 1));
    }
  }

  getContracts(idxContractList: number, page: number = 0) {
    if (!this.contractsLists[idxContractList].isLoading) {
      this.contractsLists[idxContractList].isLoading = true;
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.filter_keyword = (this.filterKeyword) ? this.filterKeyword : '';
      reqParams.list_type = this.contractsLists[idxContractList].type;
      reqParams.show_archive_contracts = (this.contractsLists[idxContractList].showArchiveContracts)?1:0;
      reqParams.show_previously_renewed_contracts = (this.contractsLists[idxContractList].showPreviouslyRenewedContracts)?1:0;
      reqParams.page = page;
      reqParams.items_per_page = 10;
      this.helper.makeAPIRequest('contracts/list', reqParams).then(response => {
        this.contractsLists[idxContractList].isLoading = false;
        if (response.success === 1) {
          if (response.data.contracts.length > 0) {
            this.contractsLists[idxContractList].records = this.contractsLists[idxContractList].records.concat(response.data.contracts);
            this.contractsLists[idxContractList].page = reqParams.page;
          }
          if (response.data.contracts.length < reqParams.items_per_page) {
            this.contractsLists[idxContractList].isEOL = true;
          }
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.contractsLists[idxContractList].isLoading = false;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  showInfo(contractID: number) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = contractID;
    this.helper.makeAPIRequest('contracts/get', reqParams).then(response => {
      if (response.success === 1) {
        this.selectedContract = response.data.record;
        this.infoModal.show();
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (response.errorCode === 4) {
          this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  handleContractObject(contract: any) {
    if (contract !== false) {
      if (this.selectedContract && this.selectedContract.id === contract.id) {
        this.selectedContract = contract;
      }
      for (let i = 0; i < this.contractsLists.length; i++) {
        for (let j = 0; j < this.contractsLists[i].records.length; j++) {
          if (+this.contractsLists[i].records[j].id === +contract.id) {
            this.contractsLists[i].records.splice(j, 1);
          }
        }
      }
      let arrIdxContractToBePushedIn: number[] = [];
      if (contract.contract_status === 'active') {
        if (contract.service_pending_date_ts < contract.curr_date_ts) {
          arrIdxContractToBePushedIn.push(0);
        } else if (contract.service_pending_id == 0 || contract.service_pending_date_ts >= contract.curr_date_ts) {
          arrIdxContractToBePushedIn.push(1);
        }
        /* if (contract.service_completed_id > 0 && contract.service_add_on_pending_id > 0) {
          arrIdxContractToBePushedIn.push(3);
        } */
      } else if (contract.contract_status === 'cancelled' || contract.contract_status === 'expired') {
        arrIdxContractToBePushedIn.push(2);
      }
      if (arrIdxContractToBePushedIn.length > 0) {
        arrIdxContractToBePushedIn.forEach(idx => {
          if (idx >= 0) {
            const contractToBePushed = { id: contract.id, contract_status: contract.contract_status, customer: { id: contract.customer.id, name: contract.customer.name }, services: contract.services, services_add_ons: contract.services_add_ons, service_pending_date: contract.service_pending_date, contract_end_date: contract.contract_end_date, completed_tickets: contract.completed_tickets, contract_service_cycles: contract.contract_service_cycles, short_address: contract.short_address, contract_note: contract.contract_note };
            this.contractsLists[idx].records.unshift(contractToBePushed);
          }
        });
      }
    }
  }

  openEntryModal(mode: string, contractID: number = 0) {
    setTimeout(() => {
      const initialState = {
        mode: mode,
        currentContractID: contractID,
        services: this.services,
        servicesAddOns: this.servicesAddOns,
        billingManagers: this.billingManagers,
      };
      this.bsModalRefEntry = this.modalService.show(ContractsEntryComponent, { initialState, class: 'modal-lg contract-entry-modal', ignoreBackdropClick: true });
      this.bsModalRefEntry.content.onClose.subscribe((contract: any) => {
        this.handleContractObject(contract);
      });
    }, 500);
  }

  cancel(contractID: number, note: string) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = contractID;
    reqParams.note = note;
    this.helper.makeAPIRequest('contracts/cancel', reqParams).then(response => {
      this.cancelContractModal.hide();
      if (response.success === 1) {
        this.handleContractObject(response.data.contract);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.cancelContractModal.hide();
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  archive(contractID: number, note: string) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = contractID;
    reqParams.note = note;
    this.helper.makeAPIRequest('contracts/archive', reqParams).then(response => {
      this.archiveContractModal.hide();
      if (response.success === 1) {
        this.handleContractObject(response.data.contract);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.archiveContractModal.hide();
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  createServiceTicket(contractServiceID: number) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.contract_service_id = contractServiceID;
    this.helper.makeAPIRequest('contracts/create_ticket', reqParams).then(response => {
      if (response.success === 1) {
        this.handleContractObject(response.data.contract);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  cancelService(contractID: number, contractServiceID: number, contractServiceNote: string) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.contract_id = contractID;
    reqParams.contract_service_id = contractServiceID;
    reqParams.contract_service_note = contractServiceNote;
    this.helper.makeAPIRequest('contracts/cancel_service', reqParams).then(response => {
      this.cancelServiceModal.hide();
      if (response.success === 1) {
        this.handleContractObject(response.data.contract);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.cancelServiceModal.hide();
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  reinstateService(contractID: number, contractServiceID: number) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.contract_id = contractID;
    reqParams.contract_service_id = contractServiceID;
    this.helper.makeAPIRequest('contracts/reinstate_service', reqParams).then(response => {
      if (response.success === 1) {
        this.handleContractObject(response.data.contract);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

}
