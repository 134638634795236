import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { Account } from '../providers/account';

import 'rxjs/add/operator/switchMap';
import { GoogleMapSearchComponent } from '../core/components/google-map-search/google-map-search.component';
import { App } from '../providers/app';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-customers',
  templateUrl: './add-edit-customers.component.html',
  styleUrls: ['./add-edit-customers.component.scss']
})
export class AddEditCustomersComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMapSearchComponent) googleMapSearchComponent: GoogleMapSearchComponent;
  public userType = 'admin';
  public frmAddEdit: FormGroup;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    name: {
      required: 'Name is required.'
    },
    email: {
      email: 'Invalid Email.'
    },
    address: {
      required: 'Address is required.'
    },
    apartment: {
      required: 'Apartment is required.'
    },
    note: {},
    billing_status: {},
    is_billing_manager: {},
    billing_manager: {}
  };

  public billingManagers: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    public app: App,
    private customValidators: IdeateValidators,
    private account: Account
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userType = params.get('type') ? params.get('type') : this.userType;
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.getRecordData();
    });

    this.initForm();
    this.getBillingManagers();
  }

  ngAfterViewInit() {
  }

  getBillingManagers() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.billing_managers_only = 1;
    reqParams.exclude = this.recordID;
    this.helper.makeAPIRequest('customers/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.billingManagers = result.data.record;
      }
    });
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('customers/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          this.record.latitude = +this.record.latitude;
          this.record.longitude = +this.record.longitude;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      // name: [this.record.name, Validators.compose([Validators.required])],
      name: [this.record.name],
      // email: [this.record.email, Validators.compose([Validators.email])],
      phone: this.formBuilder.array([]),
      address: [this.record.address, Validators.compose([Validators.required])],
      apartment: [this.record.apartment],
      note: [this.record.note],
      billing_status: [this.record.billing_status],
      is_billing_manager: [(this.recordID === 0) ? 0 : this.record.is_billing_manager],
      billing_manager: [this.helper.getObjVal(this.record, ['billing_manager', 'id'], '')],
      latitude: [this.record.latitude, Validators.compose([Validators.required])],
      longitude: [this.record.longitude, Validators.compose([Validators.required])]
    });
    if (this.record.phone && this.record.phone.length > 0) {
      for (let i = 0; i < this.record.phone.length; i++) {
        const phone = this.record.phone[i];
        this.addPhoneFieldGroup(phone);
      }
    } else {
      this.addPhoneFieldGroup();
    }
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  addPhoneFieldGroup(phone: string = '') {
    const formControl = <FormArray>this.frmAddEdit.controls['phone'];
    formControl.push(this.formBuilder.group({
      phone: [phone],
    }));
  }

  removePhoneFieldGroup(idx: number) {
    const formControl = <FormArray>this.frmAddEdit.controls['phone'];
    formControl.removeAt(idx);
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save(timeOutInterval: number = 0) {
    this.googleMapSearchComponent.validateForm();
    this.validateForm();
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        const userInputs: any = this.frmAddEdit.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.record_id = this.recordID;
        reqParams.action = (reqParams.record_id == 0) ? 'customer_create' : 'customer_update';
        reqParams.name = userInputs.name;
        // reqParams.email = userInputs.email;
        reqParams.phone = userInputs.phone;
        reqParams.address = userInputs.address;
        reqParams.apartment = userInputs.apartment;
        reqParams.note = userInputs.note;
        reqParams.billing_status = userInputs.billing_status;
        reqParams.is_billing_manager = userInputs.is_billing_manager;
        reqParams.billing_manager_id = (userInputs.is_billing_manager === 0) ? userInputs.billing_manager : 0;
        reqParams.latitude = userInputs.latitude;
        reqParams.longitude = userInputs.longitude;
        this.helper.makeAPIRequest('customers/save', reqParams).then((response) => {
          if (response.success === 1) {
            this.helper.showNotification('success', 'Customer info saved!', this.helper.config.defaultSuccessTitle);
            this.helper.navBack();
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }

  onAddressChanged(address: string) {
    this.frmAddEdit.controls['address'].setValue(address);
  }

  onLatLongChanged(latLong: any) {
    this.frmAddEdit.controls['latitude'].setValue(latLong.latitude);
    this.frmAddEdit.controls['longitude'].setValue(latLong.longitude);
  }
}

