import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeStampToDate'
})
export class TimeStampToDatePipe implements PipeTransform {

  transform(timeStampValue: any, format: string = "EEE, MMM d ''yy hh:mm aa"): any {
    const date = timeStampValue * 1000;
    return new DatePipe('en-US').transform(date, format);
		/* const date = new Date(timeStampValue * 1000);
		const dateInGMT = date.toLocaleString('en-US', {timeZone: 'GMT'});
		return new DatePipe('en-US').transform(dateInGMT, format); */
  }

}
