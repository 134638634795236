import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/internal/operators';
import { IdeateHelper } from '../core/services/ideate';

@Injectable({ providedIn: 'root' })
export class HttpInterceptorService implements HttpInterceptor {

  private whiteListURLs = ['']; // To store the list of URLs which should not be intercepted

  constructor(private ideateHelperService: IdeateHelper) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let isWhiteListedURLFound = false;

    // Check for white list URLs
    for (const whiteListURL of this.whiteListURLs) {
      if (request.url.indexOf(whiteListURL) > 0) {
        isWhiteListedURLFound = true;
        break;
      }
    }

    // Authorization header only if the request doesn't include white list URL
    if (!isWhiteListedURLFound) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'aLlAmERican2k23')
      });
    }
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        return event;
      }
    },
      (error: any) => {
      }));
  }
}
