import { Routes } from '@angular/router';
// Layouts
import {
  BlankCorporateComponent
} from './@pages/layouts';

import { Account } from './providers/account';


import { LoginComponent } from './account/login/login';
import { ResetPasswordComponent } from './account/reset_password/reset_password';
import { MyAccountComponent } from './account/edit/my_account';
import { ChangePasswordComponent } from './account/change_password/change_password';
import { BasicCorporateLayoutComponent } from './@pages/layouts/basic-corporate/basic-corporate.component';
import { TicketsComponent } from './tickets/tickets.component';
import { ContractsComponent } from './contracts/contracts.component';
import { AddEditUsersComponent } from './users/add-edit-users/add-edit-users.component';
import { UsersComponent } from './users/users.component';
import { AddEditServiceComponent } from './add-edit-service/add-edit-service.component';
import { ServicesComponent } from './services/services.component';
import { AddEditCustomersComponent } from './add-edit-customers/add-edit-customers.component';
import { CustomersComponent } from './customers/customers.component';
import { SettingsComponent } from './settings/settings';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: BlankCorporateComponent,
    children: [{
      path: '',
      component: LoginComponent
    }]
  },
  {
    path: 'reset_password',
    component: BlankCorporateComponent,
    children: [{
      path: '',
      component: ResetPasswordComponent
    }]
  },
  {
    path: '',
    redirectTo: 'tickets',
    pathMatch: 'full'
  },
  {
    path: 'account',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: 'change_password',
        component: ChangePasswordComponent
      },
      {
        path: '',
        component: MyAccountComponent
      }
    ]
  },
  {
    path: 'settings',
    component: BasicCorporateLayoutComponent,
    children: [{
      path: '',
      component: SettingsComponent
    }]
  },
  {
    path: 'customers',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: CustomersComponent
      },
      {
        path: 'add',
        component: AddEditCustomersComponent
      },
      {
        path: 'edit/:id',
        component: AddEditCustomersComponent
      }
    ]
  },
  {
    path: 'tickets',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: TicketsComponent
      },
      {
        path: 'view/:view_ticket_id',
        component: TicketsComponent
      }
    ]
  },
  {
    path: 'contracts',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: ContractsComponent
      }
    ]
  },
  {
    path: 'users',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: UsersComponent
      },
      {
        path: 'add',
        component: AddEditUsersComponent
      },
      {
        path: 'edit/:id',
        component: AddEditUsersComponent
      }
    ]
  },
  {
    path: 'services',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: ServicesComponent
      },
      {
        path: 'add',
        component: AddEditServiceComponent
      },
      {
        path: 'edit/:id',
        component: AddEditServiceComponent
      }
    ]
  }
];
